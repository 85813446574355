import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
// import styled from "@emotion/styled"

// const HeroText = styled.div`
// display: grid;

// justify-content: center;
// align-items: center;

// margin-top: 32px;

// .image {
// 	margin: 0 auto 32px auto;
// }

// h1 {
// 	font-weight: normal;
// 	font-size: 2rem;
// 	text-align: center;
// 	@media(min-width: 798px) {
//         line-height: .5;
//         margin-top: 0;
//     }
// }
// `

const GalleryHero = () => (
	<>
		<div style={{ display: "grid" }}>
			{/* You can use a GatsbyImage component if the image is dynamic */}
			<StaticImage
				style={{
					gridArea: "1/1",
					// You can set a maximum height for the image, if you wish.
					// maxHeight: 590,
				}}
				layout="fullWidth"
				// You can optionally force an aspect ratio for the generated image
				// aspectRatio={16 / 9}
				objectFit="center"
				// This is a presentational image, so the alt should be an empty string
				alt=""
				src={
					"../../components/heros/images/gallery_hotel.jpg"
				}
				formats={["auto", "webp", "avif"]}
				loading="eager"
				placeholder="tracedSVG"
			/>

			{/* add an opacity with this row if needed */}
			{/* <div
				style={{
					// By using the same grid area for both, they are stacked on top of each other
					gridArea: "1/1",
					position: "relative",
					// This centers the other elements inside the hero component
					placeItems: "center",
					display: "grid",
					background: "rgba(0, 0, 0, 0.4)"
				}}
			></div> */}
			{/* add an opacity END */}

			<div
				style={{
					// By using the same grid area for both, they are stacked on top of each other
					gridArea: "1/1",
					position: "relative",
					// This centers the other elements inside the hero component
					placeItems: "center",
					display: "grid",
				}}
			>
				{/* Any content here will be centered in the component, just modify the Greyed Out HeroText */}
				{/* <HeroText>
				</HeroText> */}
			</div>
		</div>
	</>
)

export default GalleryHero