import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
// import { Link } from "gatsby"

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import GalleryHero from '../../components/heros/galleryHero'

const StyledH1 = styled.h1`
  margin-top: 64px;
  text-align: center;
  font-size: 2rem;

  @media (min-width: 768px) {
    font-size: 6.625rem;
  }
`
const OuterWrapper = styled.div`
  max-width: var(--site-width);
  margin: 64px auto 64px auto;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  grid-gap: 16px;
`

// 16 images are coming fromn /images/gallery/temp

const GalleryIndex = () => (
  <Layout>
    <Seo title="Our Gallery" />
    <StyledH1>Our Gallery</StyledH1>
    <GalleryHero />

    <OuterWrapper>
      {/* <div className="innerWrapper"> */}
      <StaticImage
        layout="fullWidth"
        loading="lazy"
        quality={100}
        formats={['AUTO', 'WEBP', 'AVIF']}
        aspectRatio={0.75 / 1}
        src="../../images/gallery/temp/gallery_16.jpg"
        alt=""
      />
      <StaticImage
        layout="fullWidth"
        loading="lazy"
        quality={100}
        formats={['AUTO', 'WEBP', 'AVIF']}
        aspectRatio={0.75 / 1}
        src="../../images/gallery/temp/gallery_17.jpg"
        alt=""
      />
      <StaticImage
        layout="fullWidth"
        loading="lazy"
        quality={100}
        formats={['AUTO', 'WEBP', 'AVIF']}
        aspectRatio={0.75 / 1}
        src="../../images/gallery/temp/gallery_18.jpg"
        alt=""
      />
      <StaticImage
        layout="fullWidth"
        loading="lazy"
        quality={100}
        formats={['AUTO', 'WEBP', 'AVIF']}
        aspectRatio={0.75 / 1}
        src="../../images/gallery/temp/gallery_19.jpg"
        alt=""
      />
      <StaticImage
        layout="fullWidth"
        loading="lazy"
        quality={100}
        formats={['AUTO', 'WEBP', 'AVIF']}
        aspectRatio={0.75 / 1}
        src="../../images/gallery/temp/gallery_20.jpg"
        alt=""
      />
      <StaticImage
        layout="fullWidth"
        loading="lazy"
        quality={100}
        formats={['AUTO', 'WEBP', 'AVIF']}
        aspectRatio={0.75 / 1}
        src="../../images/gallery/temp/gallery_21.jpg"
        alt=""
      />

      {/* new photos were added above this line, old photos remained bottom */}
      <StaticImage
        layout="fullWidth"
        loading="lazy"
        quality={100}
        formats={['AUTO', 'WEBP', 'AVIF']}
        aspectRatio={0.75 / 1}
        src="../../images/gallery/temp/gallery_1.jpg"
        alt=""
      />
      <StaticImage
        layout="fullWidth"
        loading="lazy"
        quality={100}
        formats={['AUTO', 'WEBP', 'AVIF']}
        aspectRatio={0.75 / 1}
        src="../../images/gallery/temp/gallery_2.jpg"
        alt=""
      />
      <StaticImage
        layout="fullWidth"
        loading="lazy"
        quality={100}
        formats={['AUTO', 'WEBP', 'AVIF']}
        aspectRatio={0.75 / 1}
        src="../../images/gallery/temp/gallery_3.jpg"
        alt=""
      />
      <StaticImage
        layout="fullWidth"
        loading="lazy"
        quality={100}
        formats={['AUTO', 'WEBP', 'AVIF']}
        aspectRatio={0.75 / 1}
        src="../../images/gallery/temp/gallery_4.jpg"
        alt=""
      />
      <StaticImage
        layout="fullWidth"
        loading="lazy"
        quality={100}
        formats={['AUTO', 'WEBP', 'AVIF']}
        aspectRatio={0.75 / 1}
        src="../../images/gallery/temp/gallery_5.jpg"
        alt=""
      />
      <StaticImage
        layout="fullWidth"
        loading="lazy"
        quality={100}
        formats={['AUTO', 'WEBP', 'AVIF']}
        aspectRatio={0.75 / 1}
        src="../../images/gallery/temp/gallery_6.jpg"
        alt=""
      />
      <StaticImage
        layout="fullWidth"
        loading="lazy"
        quality={100}
        formats={['AUTO', 'WEBP', 'AVIF']}
        aspectRatio={0.75 / 1}
        src="../../images/gallery/temp/gallery_7.jpg"
        alt=""
      />
      <StaticImage
        layout="fullWidth"
        loading="lazy"
        quality={100}
        formats={['AUTO', 'WEBP', 'AVIF']}
        aspectRatio={0.75 / 1}
        src="../../images/gallery/temp/gallery_8.jpg"
        alt=""
      />
      <StaticImage
        layout="fullWidth"
        loading="lazy"
        quality={100}
        formats={['AUTO', 'WEBP', 'AVIF']}
        aspectRatio={0.75 / 1}
        src="../../images/gallery/temp/gallery_9.jpg"
        alt=""
      />
      <StaticImage
        layout="fullWidth"
        loading="lazy"
        quality={100}
        formats={['AUTO', 'WEBP', 'AVIF']}
        aspectRatio={0.75 / 1}
        src="../../images/gallery/temp/gallery_10.jpg"
        alt=""
      />
      <StaticImage
        layout="fullWidth"
        loading="lazy"
        quality={100}
        formats={['AUTO', 'WEBP', 'AVIF']}
        aspectRatio={0.75 / 1}
        src="../../images/gallery/temp/gallery_11.jpg"
        alt=""
      />
      <StaticImage
        layout="fullWidth"
        loading="lazy"
        quality={100}
        formats={['AUTO', 'WEBP', 'AVIF']}
        aspectRatio={0.75 / 1}
        src="../../images/gallery/temp/gallery_12.jpg"
        alt=""
      />
      <StaticImage
        layout="fullWidth"
        loading="lazy"
        quality={100}
        formats={['AUTO', 'WEBP', 'AVIF']}
        aspectRatio={0.75 / 1}
        src="../../images/gallery/temp/gallery_13.jpg"
        alt=""
      />
      <StaticImage
        layout="fullWidth"
        loading="lazy"
        quality={100}
        formats={['AUTO', 'WEBP', 'AVIF']}
        aspectRatio={0.75 / 1}
        src="../../images/gallery/temp/gallery_14.jpg"
        alt=""
      />
      <StaticImage
        layout="fullWidth"
        loading="lazy"
        quality={100}
        formats={['AUTO', 'WEBP', 'AVIF']}
        aspectRatio={0.75 / 1}
        src="../../images/gallery/temp/gallery_15.jpg"
        alt=""
      />
      {/* </div> */}
    </OuterWrapper>
  </Layout>
)

export default GalleryIndex
